import React from 'react';
 
class Privacy extends React.Component {

    render(){
        return (
            <React.Fragment>
                <div className="main-banner item-bg1 privacy-page">
                    <h1 className='privacy-title'>Privacy Policy</h1>
                    <p className='privacy-date'>(February 7, 2022)</p>
                    <h2 className='privacy-header'>We Respect Your Privacy!</h2>
                    <h3 className='privacy-section'>International Women of Blockchain (“IWB”) is committed to demonstrating the highest level of integrity in dealing with our customers, investors, contributors, participants and other business partners. Personal data is treated with confidentiality and in accordance with the applicable data protection laws, in particular with the EU General Data Protection Regulation (the “GDPR”).
The following Privacy Policy (“Policy”) explains how your  “Personal Information” is collected, used, and disclosed by IWB.  This Privacy Policy applies to your use and participation of any IWB sponsored application and our websites – www.womenofblockchain.org; (each a “Site” and collectively the “Sites”) and any contest, event, program or promotions we may offer (“Promotions”).   This Privacy Policy is part of and subject to the Terms of Use that govern your use of any Site or participation in any Promotion.  
                    </h3>
                    <br></br>
                    <h2 className='privacy-header underline-text'>Your Consent to This Privacy Policy.</h2>
                    <h3 className='privacy-section'>By accessing, browsing, or otherwise using any Site or participating in any Promotion, you are agreeing to the terms of this Privacy Policy. IF YOU DO NOT AGREE TO THE COLLECTION, USE AND DISCLOSURE OF YOUR PERSONAL INFORMATION AS SET FORTH IN THIS PRIVACY POLICY, PLEASE EXIT THIS SITE AND DO NOT ENTER ANY PROMOTION.</h3>
                    <br></br>
                    <h2 className='privacy-header underline-text'>Information We Collect</h2>
                    <h3 className='privacy-section'><span className="italic-text">Information You Provide.</span> We collect information about you in various ways when you use one of our Sites or enter a promotion.  For example, IWB requires you to submit Personal Information when you use certain functions or features of our Sites or enter a Promotion.  “Personal Information” is any information that identifies you personally, either alone or in combination with other information available to us. Examples of Personal Information include your name, address, email address, telephone number, or age, user name, passwords.
Any username, ID or alias that you may have in connection with a Promotion will be displayed throughout the Promotion (and so available to the public) and will be connected to all of your activity in the Promotion. In some cases, other people may see your narratives, comments, and feedback. You also have the option to publicly display your full name.
When you visit our Sites, some information is automatically collected.  For example, when you visit our Sites your computer’s operating system, Internet Protocol (IP) address, access times, browser type and language, and the website you visited before our site are logged automatically. We also collect information about your usage and activity on our Sites.
</h3>
                    <ul>
                        <li><h3 className='privacy-section'><b>Cookies.</b> We may automatically collect information using “cookies.” Cookies are small data files stored on your hard drive by a website. Among other things, cookies help us improve our Sites and your experience. We use cookies to see which areas and features are popular and to count visits to our Sites.</h3></li>
                        <li><h3 className='privacy-section'><b>Web Beacons.</b> We may collect information using Web beacons. Web beacons are electronic images that may be used on our Sites or in our emails. We use Web beacons to deliver cookies, count visits, understand usage and campaign effectiveness and to tell if an email has been opened and acted upon.</h3></li>
                        <li><h3 className='privacy-section'><b>Local Shared Objects. </b> We may use local shared objects, also known as Flash cookies, to store your preferences such as volume control or to display content based upon what you view on our sites to personalize your visit. Third party partners provide certain features on our sites and display advertising based upon your Web browsing activity using Flash cookies to collect and store information. Flash cookies are different from browser cookies because of the amount of, type of, and way in which data is stored.</h3></li>
                    </ul>
                    <br></br>

                    <h2 className='privacy-header underline-text'>How we process the data</h2>
                    <h3 className='privacy-section'>a. <span className="italic-text">Generally.</span>  IWB will use the contact we collect through for the purposes described in this Policy or disclosed to you through in the applicable rules for that specific Promotion.  For example, we may use your info to;</h3>
                    <ul>
                        <li><h3 className='privacy-section'>inform you about progress and stages of development of the Promotion or project</h3></li>
                        <li><h3 className='privacy-section'>communicate Promotion-related information,</h3></li>
                        <li><h3 className='privacy-section'>notify you in the event you are declared a Promotion winner;</h3></li>
                        <li><h3 className='privacy-section'>provide the information or services you request;</h3></li>
                        <li><h3 className='privacy-section'>for security, credit or fraud prevention purposes;</h3></li>
                        <li><h3 className='privacy-section'>provide you with a personalized experience when you use one of our Site or enter a Promotion;</h3></li>
                        <li><h3 className='privacy-section'>contact you with information and notices we believe will be of interest to you;</h3></li>
                        <li><h3 className='privacy-section'>invite you to participate in surveys and provide feedback to us;</h3></li>
                        <li><h3 className='privacy-section'>better understand your needs and interests;</h3></li>
                        <li><h3 className='privacy-section'>improve the content, functionality and usability of our Sites; and</h3></li>
                        <li><h3 className='privacy-section'>improve our services</h3></li>
                    </ul>
                    <h3 className='privacy-section'>b. <span className="italic-text">Administrative Messages.</span> IWB may also use your Personal Information to periodically email you with administrative messages.</h3>
                    <br></br>
                    <h3 className='privacy-section'>c. <span className="italic-text">Marketing.</span>  We will not sell or rent your Personal Information to third parties for their marketing purposes.</h3>
                    <br></br>
                    <h2 className='privacy-header underline-text'> Sharing of Personal Information</h2>
                    <h3 className='privacy-section'>a. <span className="italic-text">Information You Share.</span>   Our Offerings may allow you to share some of your Personal Information, such as your user name, ID or alias, along with your comments, narrative, feedback, videos, content and other information publicly or with users of our Sites.   Please be mindful of your own privacy needs as you choose who to connect with and what to share and make public.  We cannot control the privacy or security of information you choose to make public.  If you share information about others, we suggest you get consent for any information you may share about them.</h3>
                    <br></br>
                    <h3 className='privacy-section'>b. <span className="italic-text">Information We Share.</span>    We will not disclose, sell, or trade your Personal Information to third parties other than as follows:</h3>
                    <ul>
                        <li><h3 className='privacy-section'>With your consent, for example, when you agree to our sharing of your information with other third parties for their own purposes subject to their privacy policies;</h3></li>
                        <li><h3 className='privacy-section'>With third party vendors, consultants and other service providers who work for us and need access to your information to do that work;</h3></li>
                        <li><h3 className='privacy-section'>To (i) comply with laws or respond to lawful requests and legal process; (ii) to protect the rights and property of IWB our agents, customers, members, and others including to enforce our agreements, policies and terms of use or (iii) in an emergency to protect the personal safety of IWB, its customers, or any person;</h3></li>
                        <li><h3 className='privacy-section'>In connection with or during negotiation of any merger, financing, acquisition, or dissolution, transaction or proceeding involving sale, transfer, divestiture, or disclosure of all or a portion of our business or assets to another company.</h3></li>
                    </ul>
                    <h3 className='privacy-section'>We will never sell your data to anyone, however, we may share aggregated demographic, non-personal information with business partners.</h3>
                    <br></br>
                    <h2 className='privacy-header'>Third-party Services and Cross-Border Data Processing</h2>
                    <h3 className='privacy-section'>At any point, IWB may use third-party services to process personal data.  The data is only disclosed to the extent permitted by data protection regulations. Data processing outside the European Union (EU) or the European Free Trade Area (EFTA) is only performed to the extent permitted by data protection regulations unless the user provides their consent. This includes officially recognized levels of data protection such as provided through the “Privacy Shield” for the US, or compliance with officially recognized special contractual standards.</h3>
                    <br></br>
                    <h2 className='privacy-header'>Your rights</h2>
                    <h3 className='privacy-section'>You have the following rights, which may be requested at any time.</h3>
                    <ul>
                        <li><h3 className='privacy-section'>The right to request confirmation whether such data is being processed and what data is included, as well a copy of the data.</h3></li>
                        <li><h3 className='privacy-section'>The right to request the completion or correction of incomplete or incorrect data.</h3></li>
                        <li><h3 className='privacy-section'>The right to request the deletion of their data.</h3></li>
                        <li><h3 className='privacy-section'>The request to restrict the processing of your personal data</h3></li>
                        <li><h3 className='privacy-section'>The right to obtain your personal data in a structured, commonly used and machine-readable format</h3></li>
                        <li><h3 className='privacy-section'>The right to revoke their consent to process their data in the future.</h3></li>
                        <li><h3 className='privacy-section'>The right to take legal action or appeal to the data protection supervisory authorities if you are of the opinion that your rights have been infringed on due to the processing of your personal data which is not in compliance with data protection regulations.</h3></li>
                    </ul>
                    <h3 className='privacy-section'>Please note that a request to withdraw consent to any or all use or disclosure of your personal data, depending on the nature of your request, we may not be in a position to continue to provide our services or products to you or administer any contractual relationship in place. Such withdrawal may also result in the termination of any agreement you may have with us. Our legal rights and remedies are expressly reserved in such events.</h3>
                    <br></br>
                    <h2 className='privacy-header underline-text'>Changes to This Privacy Policy and Questions</h2>
                    <h3 className='privacy-section'>This Policy was lastly updated on the effective date noted above. This Policy may be amended or updated from time to time to reflect changes in our privacy practices with respect to the processing of Personal Data, or changes in applicable law. 
We encourage you to read this Policy carefully, and to regularly check this page to review any changes we might make. If you have any questions , comments or require more information about our Privacy Policy, do not hesitate to contact us at <a href="mailto:info@bwbc.io">info@bwbc.io</a>.</h3>
                </div>
            </React.Fragment>
        );
    }
}
 
export default Privacy;