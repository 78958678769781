import React from 'react';
 
class BuyTicket extends React.Component {
    render(){
        return (
            <section className="buy-tickets-area ptb-120">
                <div className="buy-tickets">
                    <div className="container">
                        {/* <div className="row align-items-center"> */}
                            <div className="col-lg-6">
                                <div className="section-title">
                                    <span>Want to Watch?</span>
                                    <h2>Coming Soon</h2>
                            
                                    <p>We'll go live March 1st! </p>
                                </div>
                            </div>

                           {/*  <div className="col-lg-6">
                                <div className="buy-ticket-btn">
                                    <Link to="#" className="btn btn-primary">View Event</Link>
                                </div>
                            </div> */}
                       {/*  </div> */}
                    </div>
                </div>
            </section>
        );
    }
}
 
export default BuyTicket;