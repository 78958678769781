import React from 'react'

class Subscribe extends React.Component {

    state = {
        term: ''
    };



    render(){
        return (
            <section className="subscribe-area">
                <div className="container">
                <a href="https://nowpayments.io/donation/IWBevents2022" target="_blank" rel="noopener noreferrer">
                    <div className="subscribe-inner">
                        <span>Want to make a contribution?</span>
                        <h2>Donate Crypto</h2>
                    </div>
                    </a>

                </div>
            </section>
        );
    }
}
 
export default Subscribe;