import React from 'react';
 
class MainBanner extends React.Component {

    state = {
        days: '',
        hours: '',
        minutes: '',
        seconds: '',
        isOpen: false,
        showTimer: true
    };

    openModal = () => {
        this.setState({isOpen: true})
    }

    makeTimer = () => {
        let endTime = new Date("March 1, 2022 9:00:00 EST");			
        let endTimeParse = (Date.parse(endTime)) / 1000;
        let now = new Date();
        let nowParse = (Date.parse(now) / 1000);
        let timeLeft = endTimeParse - nowParse;
        let days = Math.max(Math.floor(timeLeft / 86400),0); 
        let hours = Math.max(Math.floor((timeLeft - (days * 86400)) / 3600),0);
        let minutes = Math.max(Math.floor((timeLeft - (days * 86400) - (hours * 3600 )) / 60),0);
        let seconds = Math.max(Math.floor((timeLeft - (days * 86400) - (hours * 3600) - (minutes * 60))),0);        
        if (hours < "10") { hours = "0" + hours; }
        if (minutes < "10") { minutes = "0" + minutes; }
        if (seconds < "10") { seconds = "0" + seconds; }
        if (days === "00" && hours === "00" && minutes === "00" && seconds === "00"){
            this.setState({showTimer:false})
        } 
        this.setState({
            days, hours, minutes, seconds
        });
    }

    componentDidMount(){
        this.myInterval = setInterval(() => { 
            this.makeTimer();
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.myInterval);
    }

    render(){
        return (
            <React.Fragment>
                <div className="main-banner item-bg1">
                    <div className="d-table">
                        <div className="d-table-cell">
                            <div className="main-banner-content">
                                <div className="row">
                                    <div class="col-lg-6 col-md-12 col-lg-push-3 container globegb" id="myearth">
                                        <div id="glow"></div>
                                    </div>
                                    <div className="left-text-container col-lg-6 col-md-12 col-lg-pull-9">
                                        <div className="left-text-content">
                                            <p>Are you ready to attend?</p>
                                            <h1 className='title-iwb'>Welcome to the <br /><span>International Women of Blockchain</span> <br /> Conference!</h1>
                                            <h2 className='about about-event'>IWB started as a concept of Black Women Blockchain Council, benefit LLC and other Women-led blockchain organizations and enthusiasts, and has grown into a movement.  We are consciously building the block, raising awareness, and driving innovation across the globe.</h2>
                                            
                                            <ul>
                                                <li><i className="icofont-compass"></i> Anywhere, Earth</li>
                                                <li><i className="icofont-calendar"></i>March 1-31, 2022</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
    
                    {
                    this.state.showTimer ?
                        <div className="event-countdown countdown1">
                            <div id="timer">
                                <div id="days">{this.state.days} <span>Days</span></div>
                                <div id="hours">{this.state.hours} <span>Hours</span></div>
                                <div id="minutes">{this.state.minutes} <span>Minutes</span></div>
                                <div id="seconds">{this.state.seconds} <span>Seconds</span></div>
                            </div>
                        </div>
                    :
                        <></>
                    }
                </div>
            </React.Fragment>
        );
    }
}
 
export default MainBanner;