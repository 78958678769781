import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomeDefault from "./components/pages/HomeDefault";
import Navigation from "./components/Navigation/Navigation";
import NotFound from "./components/pages/NotFound";
import Privacy from "./components/pages/Privacy";
import TermsOfUse from "./components/pages/TermsOfUse";

// Conditionally render Navigation
const renderNavigation = () => {
    if (!(window.location.pathname === '/login' || window.location.pathname === '/signup' || window.location.pathname === '/coming-soon' || window.location.pathname === '/error-404')){
        return <Navigation />;
    }
}

const AppRouter = () => {
    return (
        <Router>
            {renderNavigation()}
            <Switch>
                <Route path="/" exact component={HomeDefault} />
                <Route path="/privacy" exact component={Privacy} />
                <Route path="/termsofuse" exact component={TermsOfUse} />
                <Route component={NotFound} />
            </Switch>
        </Router>
    );
};

export default AppRouter;