import React from 'react';
import { Link } from 'react-router-dom';
 
class WhyUs extends React.Component {
    render(){
        return (
            <section className="why-choose-us">
                <div className="row center m-0">
                    <div className="col-lg-6 col-sm-6 p-0">
                        <div className="single-box">
                            <div className="d-table">
                                <div className="d-table-cell">
                                    <div className="content">
                                        <div className="icon">
                                        <i class="icofont-handshake-deal"></i>
                                        </div>
                                        <h3>Sponsor</h3>
                                        {/* <p>We are excited to showcase the various areas that women are creating. Please fill out this form to help us gather interest about sponsorships.</p> */}
                                        <Link to="https://docs.google.com/forms/d/e/1FAIpQLSdBQEtwsTxbXWtyTxHpHRZiltTBGE5f5BagpxBwVFB4808Neg/viewform" className="btn btn-primary">Support</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>



{/*                     <div className="col-lg-3 col-sm-6 p-0">
                        <div className="single-box">
                            <div className="d-table">
                                <div className="d-table-cell">
                                    <div className="content">
                                        <div className="icon">
                                        <i class="icofont-eye-alt"></i>
                                        </div>
                                        <h3>View Event</h3>
                                        <p>Watch us celebrate all women in blockchain</p>
                                        <br></br>
                                        <Link to="#" className="btn btn-primary">Watch Now</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className="col-lg-6 col-sm-6 p-0">
                        <div className="single-box">
                            <div className="d-table">
                                <div className="d-table-cell">
                                    <div className="content">
                                        <div className="icon">
                                        <i class="icofont-ticket"></i>
                                        </div>
                                        <h3>Purchase Ticket</h3>
                                        {/* <p>Please fill out this form to help us spread the word.</p> */}

                                        <Link to="https://docs.google.com/forms/d/e/1FAIpQLScdW_5naaEM43OC_ooySj8b8YqQUOYYMjKozvTv1oBPAJK5cQ/closedform" className="btn btn-primary"> Buy Now</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <ul className='slideshow'>
                    <li><span></span></li>
                    <li><span></span></li>
                    <li><span></span></li>
                    <li><span></span></li>
                </ul>
            </section>
        );
    }
}
 
export default WhyUs;