import React from 'react';
 
class TermsOfUse extends React.Component {

    render(){
        return (
            <React.Fragment>
                <div className="main-banner item-bg1 privacy-page">
                    <h1 className='privacy-title'>Terms of Use</h1>
                    <p className='privacy-date'>(February 7, 2022)</p>
                    <h3 className='privacy-section'>Your use and access of the Sites is governed by and subject to these terms of use (“Terms”).  If you do not agree to these Terms, or if you do not agree with our Privacy Policy at www.womenofblockchain.org/privacy, please do not use any Site.  PLEASE READ THESE TERMS AND CONDITIONS CAREFULLY BEFORE USING ANY SITE. BY ACCESSING, BROWSING, OR OTHERWISE USING ANY OF OUR SITES, YOU ACKNOWLEDGE THAT YOU HAVE READ, UNDERSTAND AND AGREE TO BE BOUND BY THESE TERMS AND BY OUR PRIVACY POLICY.</h3>
                    <br></br>
                    <h3 className='privacy-section'>The Services and the website at <a href="http://www.womenofblockchain.org">www.womenofblockchain.org</a> (the “Site”) and their related content are provided to you “as is” and on an “as available” basis and without guarantee. While every effort has been made to offer up-to-date and accurate information, errors can occur. Although International Women of Blockchain (IWB) may make changes to the content in the Services and on the Site at any time without notice, Services does not make any commitment to update the Services and the Site. Without limiting the foregoing, IWB does not represent or warrant that this Site, its content, or any information or other materials downloaded from the Services and the Site will be accurate, current, uninterrupted, secure, error-free, omission-free, free of viruses or other harmful components, or will meet any particular criteria of performance or quality.</h3>
                    <br></br>
                    <h3 className='privacy-section'>Subject to these Terms and our Privacy Policy, you are granted a limited license to use the functionality provided by the Sites to upload, store, display, aggregate, organize and share your content (“Content”) and to copy, display, and use the functionality, materials, and services provided by the Site (collectively, the “Materials”) solely for non-commercial use.  The design and layout of each Site is specifically excluded from the Materials.</h3>
                    <br></br>
                    <h3 className='privacy-section'>Except for your Content, all text, content, documents, names, logos, trademarks, service marks, brand identities, characters, trade names, graphics, designs, copyrights, trade dress, or other intellectual property appearing in each Site, and the organization, compilation, look and feel, illustrations, artwork, videos, music, software and other works on the Sites (the “Materials”) are owned by IWB and its affiliates or used with permission or under license from a third party (hereinafter collectively referred to as the “Owner”) and are protected under copyright, trademark and other intellectual property and proprietary rights laws.</h3>
                    <br></br>
                    <h3 className='privacy-section'>Your submission or personal information and other information are subject to our privacy policy. Your information may be stored and processed in the United States and by subscribing to the Services, you consent to the transfer of information to the United States. If you access the Services using a password, you are solely responsible for maintaining the confidentiality of that password. You agree to notify us promptly if you change your address or email so we can continue to contact you and send any notices required hereunder. If you fail to notify us promptly of a change, then any notice we send to your old address or email shall be deemed sufficient notice.</h3>
                    <br></br>
                    <h3 className='privacy-section'>We take steps to minimize the collection of personal data from Users under the age of 13 (a <b>“Child”</b>). Parents/Guardians may register accounts on the Services for one or more Users under the age of 13. If you are Parents/Guardians and you register an account for a Child who is under the age of 13, you represent and warrant that you have proper permission to register the Child for any of our Services, and that you have obtained the necessary, or give parental consent for IWB’s collection of the Child’s personal information for the use and benefit of the User and for no other commercial purpose.</h3>
                    <br></br>
                    <h3 className='privacy-section'>We reserve the right to terminate or restrict your access to the Services if, in our opinion, your use of the Services may violate any laws, regulations or rulings, infringe upon another person’s rights or violate the terms of this Terms of Service.</h3>
                    <br></br>
                    <h3 className='privacy-section'>YOU AGREE THAT YOUR USE OF THE Services AND THE CONTENT AVAILABLE THROUGH THE Services IS ON AN “AS-IS”, “AS AVAILABLE” BASIS AND WE SPECIFICALLY DISCLAIM ANY REPRESENTATIONS OR WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY REPRESENTATIONS OR WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. In addition, IWB makes no representations or warranties of any kind in connection with the subject matter included on or the use of the Site, and use of the Site is subject to the disclaimers, terms and conditions of use found at the Site.</h3>
                    <br></br>
                    <h3 className='privacy-section'>You must not use this website in any way that causes, or may cause, damage to the website or impairment of the availability or accessibility of the website; or in any way which is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose or activity. You must not use this website to copy, store, host, transmit, send, use, publish or distribute any material which consists of (or is linked to) any malicious software. You must not conduct any systematic or automated data collection activities (including without limitation scraping, data mining, data extraction and data harvesting) on or in relation to this website without IWB’s express written consent. You must not use this website to transmit or send unsolicited commercial communications.You must not use this website for any purposes related to marketing without IWB’s express written consent. IWB reserves the right to restrict access to certain areas of this website, or indeed this entire website, at its discretion.</h3>
                    <br></br>
                    <h3 className='privacy-section'>IWB WILL NOT BE LIABLE (JOINTLY OR SEVERALLY) TO YOU OR ANY OTHER PERSON AS A RESULT OF YOUR ACCESS TO OR USE OF THE SERVICES, FOR INDIRECT, CONSEQUENTIAL, SPECIAL, INCIDENTAL, PUNITIVE, OR EXEMPLARY DAMAGES, INCLUDING, WITHOUT LIMITATION, LOST PROFITS, LOST SAVINGS AND LOST REVENUES (COLLECTIVELY, THE “EXCLUDED DAMAGES”), WHETHER OR NOT CHARACTERIZED IN NEGLIGENCE, TORT, CONTRACT, OR OTHER THEORY OF LIABILITY, EVEN IF ANY OF THE IWB PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF OR COULD HAVE FORESEEN ANY OF THE EXCLUDED DAMAGES, AND IRRESPECTIVE OF ANY FAILURE OF AN ESSENTIAL PURPOSE OF A LIMITED REMEDY. IF ANY APPLICABLE AUTHORITY HOLDS ANY PORTION OF THIS SECTION TO BE UNENFORCEABLE, THEN THE IWB PARTIES’ LIABILITY WILL BE LIMITED TO THE FULLEST POSSIBLE EXTENT PERMITTED BY APPLICABLE LAW. IN NO EVENT WILL ANY OF THE IWB PARTIES BE LIABLE FOR ANY DAMAGES OR LOSSES RESULTING FROM ANY DECISIONS YOU MAKE AS A RESULT OF ACCESSING OR USING THE SERVICES.</h3>
                    <br></br>
                    <h3 className='privacy-section'>By using the Services, you accept the foregoing disclaimers and limitations on liability and you assume all risks associated with such use (including without limitation, risk to your computer, software or data being damaged by any virus transmitted or activated via the Services or material that may be included within or accessed from the Services). Further, the presence of any hyperlinks from the Services to other websites operated by parties other than IWB does not imply any kind of endorsement of the content on such websites by IWB or any association with the owners or operators of such websites, and the views expressed at such websites that may be linked through the Services are not necessarily the views of IWB or its employees. Such hyperlinks are provided for information and reference purposes only and IWB is not responsible for the availability of these external websites or for any of the contents, advertising, products, or other materials on such websites. Any concerns regarding any hyperlink should be directed to the respective website owner or operator/webmaster.</h3>
                    <br></br>
                    <h3 className='privacy-section'>IWB may transfer, sub-contract or otherwise deal with the organization’s rights and/or obligations under these terms and conditions without notifying you or obtaining your consent. You may not transfer, sub-contract or otherwise deal with your rights and/or obligations under these terms and conditions.</h3>
                    <br></br>
                    <h3 className='privacy-section'>THE SERVICES IS BASED ON INFORMATION AVAILABLE AS OF THE TIME IT WAS WRITTEN. IWB AND ITS INFORMATION PROVIDERS DISCLAIM ANY DUTY TO UPDATE THE SERVICES. IN PARTICULAR, BY CONTINUING TO MAKE THE CONTENT OF THE SERVICES AVAILABLE, ON THE SITES OR OTHERWISE, IWB DOES NOT MAKE ANY REPRESENTATION AS TO THE TIMELINESS OF THE INFORMATION IN THE SERVICES.</h3>
                    <br></br>
                    <h3 className='privacy-section'>This Terms of Service contains the final and entire agreement between us regarding your use of the Services and supersedes all previous and contemporaneous oral or written agreements regarding your use of the Services. We may discontinue or change the Services, or its availability to you, at any time. This Terms of Service is personal to you, which means that you may not assign your rights or obligations under this Terms of Service to anyone. No third party is a beneficiary of this Terms of Service. You agree that these Terms of Service and any legal action or proceeding relating to this Website shall be governed by the laws of the State of Maryland without reference to its choice of law rules. The sole jurisdiction and venue for any litigation arising out of this Terms of Service will be an appropriate federal or state court located in the State of Maryland.</h3>
                </div>
            </React.Fragment>
        );
    }
}
 
export default TermsOfUse;