import React from 'react';
import { Link } from 'react-router-dom';
import discordLogo from '../../assets/images/discord.svg';

class Footer extends React.Component {
    render(){
        return (
            <footer className="footer-area">
                <div className="container">
                    <div className="single-footer-widget">
                        <h3 id="mobile-header">Testimonals</h3>
                    </div>
                    <div className="row">

                        <div className="col-lg-6 col-md-12 col-sm-12 footer-large-image-frame">
                            <div className="single-footer-widget footer-large-image-container">
                                <img id="footer-large-image" alt="girl power" src={require("../../assets/images/GirlPower.png")}></img>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12">
                            <div className="single-footer-widget">
                                <h3 id="normal-header">Testimonals</h3>
                                <p>Building community is vital for our life force. Recognizing and celebrating women in this field inspires us all and shows our daughters models they can relate to. Celebrating the diversity of thoughts and backgrounds in a technology, that at its core is decentralized and autonomous, is something to experience. This celebration should happen regularly and not be missed. I joined this celebration to add the voice of real estate tokenization to the blockchain conversation. <span>-Theresa Kennedy</span></p>
                                <br></br>
                                <p>A whole new world is unfolding before our very eyes. Collectively, as a diverse body, we have the power to inform people of the possibilities of blockchain, new financial models, and self-governance, all while connecting with and aiding the community. I hope to humbly share what I have learned as a connector of people, collector, and artist in this new space.<span>- Crystal Petit</span></p>
                                <br></br>
                                <p>"NFTs are revolutionizing the arts, changing the lives of thousands of artists that were before struggling to make a living from their passion. Anyone should be able to access the wealth of new creative and commercial opportunities that this groundbreaking technology offers, no matter their gender, where they live or what language they speak"- Sabrina Bonini</p>
                            </div>
                        </div>
                    </div>
                    <div>
                        <br></br>
                        <br></br>
                        <br></br>
                        <h2 className='socials'>Socials</h2>
                        <p className='socials'>Connect with Us</p>
                        
                        <div className="single-footer-widget">
                            <ul className="social-links">
                                <li>
                                    <Link to="https://www.facebook.com/IWBevent" className="facebook" target="_blank">
                                        <i className="icofont-facebook"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://twitter.com/IWBevent" className="twitter" target="_blank">
                                        <i className="icofont-twitter"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://www.instagram.com/iwbevents/" className="instagram" target="_blank">
                                        <i className="icofont-instagram"></i>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="https://www.instagram.com/iwbevents/" className="discord" target="_blank">
                                        <img alt="discord logo" src={discordLogo} />
                                    </Link>
                                </li>
                            </ul>
                        </div>

                        <div className="col-lg-12">
                            <div className="copyright-area">
                                <div className='row logo-area'>
                                    <div className='col-lg-2 col-sm-12'></div>
                                    <div className="col-lg-4 col-sm-12 logo">
                                        <Link to="/">
                                            <img width="50%"src={require("../../assets/images/logo.png")} alt="International Women Blockchain logo" />
                                        </Link>
                                    </div>
                                    <div className="col-lg-4 col-sm-12 logo">
                                        <Link to="/">
                                            <img width="50%"src={require("../../assets/images/bwbclogo.png")} alt="Black Women in Blockchain Logo" />
                                        </Link>
                                    </div>
                                    <div className='col-lg-2 col-sm-12'></div>
                                </div>
                                <ul>
                                    <li><Link to="/privacy">Privacy</Link></li>
                                    <li><Link to="/termsofuse">Terms of Use</Link></li>
                                </ul>
                                <p>
                                    Copyright <i className="icofont-copyright"></i> 2022 International Women Blockchain. All rights reserved
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}
 
export default Footer;